document.addEventListener('DOMContentLoaded', function () {
    const banners = document.querySelectorAll(".dynamic-banner");

    var customerData = getCustomerPreference(),
        organisationType = '';

    if (customerData && customerData.customer) {
        organisationType = customerData.customer.organisation_type;
    }

    var selector = false;
    switch (organisationType) {
        case 'P':
            selector = '.dynamic-banner__banner--private';
            break;
        case 'C':
            selector = '.dynamic-banner__banner--business';
            break;
        case 'G':
            selector = '.dynamic-banner__banner--governmental';
            break;
        case 'I':
            selector = '.dynamic-banner__banner--installer';
            break;
        default:
            selector = false;
            break;
    }

    banners.forEach((banner) => {
        const currentBanner = document.querySelector(`#${banner.id} .dynamic-banner__banner--unknown`);

        if (selector) {
            const dynamicBanner = document.querySelector(`#${banner.id} ${selector}`);

            if (dynamicBanner) {
                dynamicBanner.style.display = 'block';
                currentBanner.style.display = 'none';
            }
        }
    });

    function getCustomerPreference()
    {
        const cacheStorage = localStorage.getItem('mage-cache-storage');
        if (!cacheStorage) {
            return null;
        }
        const parsedCache = JSON.parse(cacheStorage);
        if (!parsedCache || !parsedCache.hasOwnProperty('customer-preference')) {
            return null;
        }
        return parsedCache;
    }
});
